
import { useMetaDatosStore } from "~/store/metadatos";

export default function useMetadatos(metaFromPage:Object){

   
    const route = useRoute();
    let page = route.path?.toString();



    page = page.slice(1,-1);

    page = page.replaceAll('/','-');

    
    const {metadatos} = useMetaDatosStore();

    const metaDB = metadatos.find(elem => elem.slug == page) || false;

    const url = useRequestURL();

    useHead({
        title: metaDB?.title || metaFromPage?.title || 'Concesionarios Marcos Automoción',
        meta: [
        { 
          name: 'description',
          content: metaDB?.description || metaFromPage?.description || 'Ven a nuestras instalaciones y descubre las mejores ofertas de vehículos de las marcas más prestigiosas del mercado. ✅ ¡Te esperamos!'
        }
        ],
        link: [
            {
              rel: 'canonical',
              href: metaDB?.canonical || metaFromPage?.canonical || url.host + route.path
            }
          ]
    
    });

}